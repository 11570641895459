<template>
  <div class="showcases-grand-wrapper">
    <transition name="slide-top" mode="out-in">
      <div class="showcases-wrapper" v-if="opened === null">
        <header :style="{
        'background-color':
          opened !== null ? type[opened].color : '#cfced3',
      }">
          <NotFound v-if="$route.name === '404'" />
          <template v-else>
            <aside>
              <transition name="slide" mode="out-in">
                <img :src="require(`@/assets/img/${$route.name}/svg_${$i18n.locale}.svg`)
        " class="svg" :key="`showcases-${$route.name}-svg`" :alt="$t(`showcases-${$route.name}-alt`)" />
              </transition>
            </aside>
            <div class="showcases" :class="{ big: type.length < 5 }">
              <div v-for="(r, i) in type" class="showcase" :key="`showcase-${$route.name}-${i}`"
                @click="openShowcase(r)">
                <transition name="appear-bg" appear>
                  <div class="bg"></div>
                </transition>
                <transition name="appear-img" appear>
                  <div class="img" :style="{ backgroundImage: `url(${r.bg})` }" alt="Background image" />
                </transition>
                <div class="info">
                  <img :src="r.svg" :alt="r.name ? r.name.join(' ') : r.ttl" v-if="r.svg" />
                  <h2 v-else-if="r.name">
                    <p v-for="(n, k) in r.name" :key="`room-${i}-info-${k}`">
                      {{ n }}
                    </p>
                  </h2>
                  <div class="texts">
                    <p v-for="(info, k) in r.info" :key="`room-${i}-info-${k}`" v-html="info"></p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </header>
      </div>
      <Showcase v-else :showcase="type[opened]" @close="closeShowcase()" :previousShowcase="opened > 0 ? type[opened - 1] : type[type.length - 1]
        " :nextShowcase="opened < type.length - 1 ? type[opened + 1] : type[0]" />
    </transition>
  </div>
</template>

<script>
import Showcase from "./Showcase";
import NotFound from "./NotFound";
// import Offer from "./Offer";
export default {
  components: {
    Showcase,
    NotFound,
    // Offer,
  },
  computed: {
    type() {
      return this[this.$route.name];
    },
    getTallest() {
      const sc = document.getElementsByClassName("showcase__footer");
      let tallest = 0;
      for (let s of sc) {
        if (tallest < s.offsetHeight) {
          tallest = s.offsetHeight;
        }
      }

      return tallest;
    },
  },
  data() {
    return {
      rooms: [
        {
          id: "junior-suite",
          bg: require("@/assets/img/rooms/1/bg.jpg"),
          svg: require(`@/assets/img/rooms/1/svg_${this.$i18n.locale}.svg`),
          // prices: require(`@/assets/img/rooms/1/prices_${this.$i18n.locale}.svg`),
          name: this.$t("room-1-name"),
          info: this.$t("room-1-info"),
          options: [
            {
              img: require("@/assets/img/showcase/mountain.svg"),
              txt: this.$t("room-1-option-1"),
            },
          ],
          ttl: this.$t("room-1-ttl"),
          sttl: this.$t("room-1-sttl"),
          txts: this.$t("room-1-txts"),
          gallery: [
            // if you need to add videos, just require it the same way as an image
            // * works only for mp4 files
            // require("@/assets/img/home/parallax/video.mp4"),
            require("@/assets/img/rooms/1/g1.jpg"),
            require("@/assets/img/rooms/1/g2.jpg"),
            require("@/assets/img/rooms/1/g3.jpg"),
            require("@/assets/img/rooms/1/g4.jpg"),
            require("@/assets/img/rooms/1/g5.jpg"),
            require("@/assets/img/rooms/1/g6.jpg"),
          ],
          inquiry: {
            mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
            bedrooms: [],
          },
        },
        {
          id: "executive-suite",
          bg: require("@/assets/img/rooms/2/bg.jpg"),
          svg: require(`@/assets/img/rooms/2/svg_${this.$i18n.locale}.svg`),
          // prices: require(`@/assets/img/rooms/2/prices_${this.$i18n.locale}.svg`),
          name: this.$t("room-2-name"),
          info: this.$t("room-2-info"),
          options: [
            {
              img: require("@/assets/img/showcase/mountain.svg"),
              txt: this.$t("room-2-option-1"),
            },
            {
              img: require("@/assets/img/showcase/superior.svg"),
              txt: this.$t("room-2-option-2"),
            },
          ],
          ttl: this.$t("room-2-ttl"),
          sttl: this.$t("room-2-sttl"),
          txts: this.$t("room-2-txts"),
          gallery: [
            require("@/assets/img/rooms/2/g1.jpg"),
            require("@/assets/img/rooms/2/g3.jpg"),
            require("@/assets/img/rooms/2/g2.jpg"),
            require("@/assets/img/rooms/2/g4.jpg"),
            require("@/assets/img/rooms/2/g5.jpg"),
            require("@/assets/img/rooms/2/g6.jpg"),
          ],
          inquiry: {
            mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
            bedrooms: ["1", "2", "3"],
          },
        },
        {
          id: "grand-suite",
          bg: require("@/assets/img/rooms/6/bg.jpg"),
          svg: require(`@/assets/img/rooms/6/svg_${this.$i18n.locale}.svg`),
          // prices: require(`@/assets/img/rooms/6/prices_${this.$i18n.locale}.svg`),
          name: this.$t("room-6-name"),
          info: this.$t("room-6-info"),
          options: [
            {
              img: require("@/assets/img/showcase/mountain.svg"),
              txt: this.$t("room-6-option-1"),
            },
            {
              img: require("@/assets/img/showcase/superior.svg"),
              txt: this.$t("room-6-option-2"),
            },
          ],
          ttl: this.$t("room-6-ttl"),
          sttl: this.$t("room-6-sttl"),
          txts: this.$t("room-6-txts"),
          gallery: [
            require("@/assets/img/rooms/6/g1.jpg"),
            require("@/assets/img/rooms/6/g3.jpg"),
            require("@/assets/img/rooms/6/g2.jpg"),
            require("@/assets/img/rooms/6/g4.jpg"),
            require("@/assets/img/rooms/6/g5.jpg"),
            require("@/assets/img/rooms/6/g6.jpg"),
            require("@/assets/img/rooms/6/g7.jpg"),
            require("@/assets/img/rooms/6/g8.jpg"),
          ],
          inquiry: {
            mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
            bedrooms: ["1", "2"],
          },
        },
        {
          id: "king-suite",
          bg: require("@/assets/img/rooms/10/bg.jpg"),
          svg: require(`@/assets/img/rooms/10/svg_${this.$i18n.locale}.svg`),
          // prices: require(`@/assets/img/rooms/10/prices_${this.$i18n.locale}.svg`),
          name: this.$t("room-10-name"),
          info: this.$t("room-10-info"),
          ttl: this.$t("room-10-ttl"),
          sttl: this.$t("room-10-sttl"),
          txts: this.$t("room-10-txts"),
          gallery: [
            require("@/assets/img/rooms/10/g1.jpg"),
            require("@/assets/img/rooms/10/g3.jpg"),
            require("@/assets/img/rooms/10/g2.jpg"),
            require("@/assets/img/rooms/10/g4.jpg"),
            require("@/assets/img/rooms/10/g5.jpg"),
            require("@/assets/img/rooms/10/g6.jpg"),
            require("@/assets/img/rooms/10/g7.jpg"),
            require("@/assets/img/rooms/10/g8.jpg"),
            require("@/assets/img/rooms/10/g9.jpg"),
            require("@/assets/img/rooms/10/g10.jpg"),
            require("@/assets/img/rooms/10/g11.jpg"),
            require("@/assets/img/rooms/10/g12.jpg"),
            require("@/assets/img/rooms/10/g13.jpg"),
            require("@/assets/img/rooms/10/g14.jpg"),
          ],
          inquiry: {
            mountain: [],
            bedrooms: [],
          },
        },
      ],
      halls: [
        // {
        //   id: "1",
        //   bg: require("@/assets/img/halls/1/bg.jpg"),
        //   svg: require(`@/assets/img/halls/1/svg_${this.$i18n.locale}.svg`),
        //   prices: require(`@/assets/img/halls/1/capacity_${this.$i18n.locale}.svg`),
        //   name: this.$t("hall-1-name"),
        //   info: this.$t("hall-1-info"),
        //   description: this.$t("hall-1-description"),
        //   ttl: this.$t("hall-1-ttl"),
        //   sttl: this.$t("hall-1-sttl"),
        //   txts: this.$t("hall-1-txts"),
        //   // additionalGallery: [require("@/assets/img/halls/1/hall-str.svg")],
        //   gallery: [
        //     require("@/assets/img/halls/1/g1.jpg"),
        //     require("@/assets/img/halls/1/g2.jpg"),
        //   ],
        //   aboutImages: [
        //     require("@/assets/img/halls/1/a1.jpg"),
        //     require("@/assets/img/halls/1/a2.jpg"),
        //     require("@/assets/img/halls/1/a3.jpg"),
        //   ],
        //   color: "#E4D7D7",
        //   features: [1, 2, 3, 4, 5, 6],
        //   optionalFeatures: [1, 2, 4],
        // },
        {
          id: "dardaneli",
          bg: require("@/assets/img/halls/2/bg.jpg"),
          svg: require(`@/assets/img/halls/2/svg_${this.$i18n.locale}.svg`),
          prices: require(`@/assets/img/halls/2/capacity_${this.$i18n.locale}.svg`),
          name: this.$t("hall-2-name"),
          info: this.$t("hall-2-info"),
          description: this.$t("hall-2-description"),
          ttl: this.$t("hall-2-ttl"),
          sttl: this.$t("hall-2-sttl"),
          txts: this.$t("hall-2-txts"),
          // additionalGallery: [require("@/assets/img/halls/2/hall-str.svg")],
          gallery: [
            require("@/assets/img/halls/2/g1.jpg"),
            require("@/assets/img/halls/2/g2.jpg"),
            require("@/assets/img/halls/2/g3.jpg"),
            require("@/assets/img/halls/2/g4.jpg"),
            require("@/assets/img/halls/2/g5.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/halls/2/a1.jpg"),
            require("@/assets/img/halls/2/a2.jpg"),
            require("@/assets/img/halls/2/a3.jpg"),
          ],
          color: "#E4E4D7",
          features: [1, 2, 3, 4, 5, 6],
          optionalFeatures: [1, 2, 3, 4, 6],
        },
        {
          id: "bosphorus1-2",
          bg: require("@/assets/img/halls/3/bg.jpg"),
          svg: require(`@/assets/img/halls/3/svg_${this.$i18n.locale}.svg`),
          prices: require(`@/assets/img/halls/3/capacity_${this.$i18n.locale}.svg`),
          name: this.$t("hall-3-name"),
          info: this.$t("hall-3-info"),
          description: this.$t("hall-3-description"),
          ttl: this.$t("hall-3-ttl"),
          sttl: this.$t("hall-3-sttl"),
          txts: this.$t("hall-3-txts"),
          // additionalGallery: [require("@/assets/img/halls/3/hall-str.svg")],
          gallery: [
            require("@/assets/img/halls/3/g1.jpg"),
            require("@/assets/img/halls/3/g2.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/halls/3/a1.jpg"),
            require("@/assets/img/halls/3/a2.jpg"),
            require("@/assets/img/halls/3/a3.jpg"),
          ],
          color: "#E4DED7",
          features: [1, 2, 3, 4, 5, 6],
          optionalFeatures: [1, 2, 3, 4, 6],
        },
        {
          id: "bosphorus-grand",
          bg: require("@/assets/img/halls/4/bg.jpg"),
          svg: require(`@/assets/img/halls/4/svg_${this.$i18n.locale}.svg`),
          prices: require(`@/assets/img/halls/4/capacity_${this.$i18n.locale}.svg`),
          name: this.$t("hall-4-name"),
          info: this.$t("hall-4-info"),
          description: this.$t("hall-4-description"),
          ttl: this.$t("hall-4-ttl"),
          sttl: this.$t("hall-4-sttl"),
          txts: this.$t("hall-4-txts"),
          // additionalGallery: [require("@/assets/img/halls/4/hall-str.svg")],
          gallery: [
            require("@/assets/img/halls/4/g1.jpg"),
            require("@/assets/img/halls/4/g2.jpg"),
            require("@/assets/img/halls/4/g3.jpg"),
            require("@/assets/img/halls/4/g4.jpg"),
            require("@/assets/img/halls/4/g5.jpg"),
            require("@/assets/img/halls/4/g6.jpg"),
            require("@/assets/img/halls/4/g7.jpg"),
            require("@/assets/img/halls/4/g8.jpg"),
            require("@/assets/img/halls/4/g9.jpg"),
            require("@/assets/img/halls/4/g10.jpg"),
            require("@/assets/img/halls/4/g11.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/halls/4/a1.jpg"),
            require("@/assets/img/halls/4/a2.jpg"),
            require("@/assets/img/halls/4/a3.jpg"),
          ],
          color: "#E0D7E4",
          features: [1, 2, 3, 4, 5, 6],
          optionalFeatures: [1, 2, 3, 4, 5, 6],
        },
        {
          id: "banquet-events",
          bg: require("@/assets/img/halls/5/bg.jpg"),
          svg: require(`@/assets/img/halls/5/svg_${this.$i18n.locale}.svg`),
          prices: require(`@/assets/img/halls/5/capacity_${this.$i18n.locale}.svg`),
          name: this.$t("hall-5-name"),
          info: this.$t("hall-5-info"),
          description: this.$t("hall-5-description"),
          ttl: this.$t("hall-5-ttl"),
          sttl: this.$t("hall-5-sttl"),
          txts: this.$t("hall-5-txts"),
          // additionalGallery: [require("@/assets/img/halls/5/hall-str.svg")],
          gallery: [
            require("@/assets/img/halls/5/g1.jpg"),
            require("@/assets/img/halls/5/g2.jpg"),
            require("@/assets/img/halls/5/g3.jpg"),
            require("@/assets/img/halls/5/g4.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/halls/5/a1.jpg"),
            require("@/assets/img/halls/5/a2.jpg"),
            require("@/assets/img/halls/5/a3.jpg"),
          ],
          color: "#EAEAEC",
          features: [1, 2, 3, 4, 5, 6],
          optionalFeatures: [1, 2, 3, 4, 5, 6],
        },
      ],
      // NEWS
      news: [
        {
          id: "renovation-2025",
          bg: require("@/assets/img/news/2025-renovation/bg.jpg"),
          name: this.$t("news-24-name"),
          info: [this.$t("news-24-subname")],
          description: this.$t("news-24-description"),
          ttl: this.$t("news-24-ttl"),
          sttl: this.$t("news-24-sttl"),
          txts: this.$t("news-24-txts"),
          color: "#163d8e",
        },
        {
          id: "renovation-2024",
          bg: require("@/assets/img/news/2024-renovation/bg.jpg"),
          name: this.$t("news-23-name"),
          info: [this.$t("news-23-subname")],
          description: this.$t("news-23-description"),
          ttl: this.$t("news-23-ttl"),
          sttl: this.$t("news-23-sttl"),
          txts: this.$t("news-23-txts"),
          color: "#163d8e",
        },
        {
          id: "Upcoming-Events-2024",
          bg: require("@/assets/img/news/2024-Upcoming-Events/bg.jpg"),
          name: this.$t("news-22-name"),
          info: [this.$t("news-22-subname")],
          description: this.$t("news-22-description"),
          ttl: this.$t("news-22-ttl"),
          sttl: this.$t("news-22-sttl"),
          txts: this.$t("news-22-txts"),
          color: "#163d8e",
        },
        {
          id: "el-car-power-stations",
          bg: require("@/assets/img/news/2024-el-car-power-stations/bg.jpg"),
          name: this.$t("news-21-name"),
          info: [this.$t("news-21-subname")],
          description: this.$t("news-21-description"),
          ttl: this.$t("news-21-ttl"),
          sttl: this.$t("news-21-sttl"),
          txts: this.$t("news-21-txts"),
          color: "#163d8e",
        },
        {
          id: "ski-pass-2024",
          bg: require("@/assets/img/news/2024-ski-pass/bg.jpg"),
          name: this.$t("news-20-name"),
          info: [this.$t("news-20-subname")],
          description: this.$t("news-20-description"),
          ttl: this.$t("news-20-ttl"),
          // sttl: this.$t("news-20-sttl"),
          txts: this.$t("news-20-txts"),
          color: "#163d8e",
        },
        {
          id: "regnum-banya-new-year-2023-2024",
          bg: require("@/assets/img/news/2023-nye/bg.jpg"),
          name: this.$t("news-19-name"),
          info: [this.$t("news-19-subname")],
          description: this.$t("news-19-description"),
          ttl: this.$t("news-19-ttl"),
          // sttl: this.$t("news-19-sttl"),
          txts: this.$t("news-19-txts"),
          color: "#163d8e",
        },
        {
          id: "regnum-banya-thermal-under-repair-2023",
          bg: require("@/assets/img/news/2023-banya/bg.jpg"),
          name: this.$t("news-18-name"),
          info: [this.$t("news-18-subname")],
          description: this.$t("news-18-description"),
          ttl: this.$t("news-18-ttl"),
          // sttl: this.$t("news-18-sttl"),
          txts: this.$t("news-18-txts"),
          color: "#163d8e",
        },
        {
          id: "2023-business-training",
          bg: require("@/assets/img/news/2023-business-training/bg.jpg"),
          name: this.$t("news-17-name"),
          info: [this.$t("news-17-description")],
          description: this.$t("news-17-description"),
          ttl: this.$t("news-17-ttl"),
          sttl: this.$t("news-17-sttl"),
          txts: this.$t("news-17-txts"),
          color: "#173d8e",
        },
        {
          id: "vip-ski-pass",
          bg: require("@/assets/img/news/2023-vip/bg.jpg"),
          name: this.$t("news-16-name"),
          info: [this.$t("news-16-description")],
          description: this.$t("news-16-description"),
          ttl: this.$t("news-16-ttl"),
          sttl: this.$t("news-16-sttl"),
          txts: this.$t("news-16-txts"),
          color: "#163d8e",
        },
        // {
        //   id: "regnum-new-year-plans",
        //   bg: require("@/assets/img/news/2022-nye/bg.jpg"),
        //   name: this.$t("news-15-name"),
        //   info: [this.$t("news-15-subname")],
        //   description: this.$t("news-15-description"),
        //   ttl: this.$t("news-15-ttl"),
        //   sttl: this.$t("news-15-sttl"),
        //   txts: this.$t("news-15-txts"),
        //   color: "#163d8e",
        // },
        {
          id: "regnum-banya-thermal-under repair",
          bg: require("@/assets/img/news/2022-banya/bg.jpg"),
          name: this.$t("news-14-name"),
          info: [this.$t("news-14-subname")],
          description: this.$t("news-14-description"),
          ttl: this.$t("news-14-ttl"),
          sttl: this.$t("news-14-sttl"),
          txts: this.$t("news-14-txts"),
          color: "#163d8e",
        },
        {
          id: "lift-summer-bansko-2022",
          bg: require("@/assets/img/news/2022-lift/bg.jpg"),
          name: this.$t("news-13-name"),
          info: [this.$t("news-13-subname")],
          description: this.$t("news-13-description"),
          ttl: this.$t("news-13-ttl"),
          sttl: this.$t("news-13-sttl"),
          txts: this.$t("news-13-txts"),
          color: "#163d8e",
        },
        {
          id: "jazz-fest-2022",
          bg: require("@/assets/img/news/2022-jazz/bg.jpg"),
          name: this.$t("news-12-name"),
          info: [this.$t("news-12-subname")],
          description: this.$t("news-12-description"),
          ttl: this.$t("news-12-ttl"),
          sttl: this.$t("news-12-sttl"),
          txts: this.$t("news-12-txts"),
          color: "#163d8e",
        },
        {
          id: "summer-attractions-2022",
          bg: require("@/assets/img/news/2022-summer/bg.jpg"),
          name: this.$t("news-11-name"),
          info: [this.$t("news-11-subname")],
          description: this.$t("news-11-description"),
          ttl: this.$t("news-11-ttl"),
          sttl: this.$t("news-11-sttl"),
          txts: this.$t("news-11-txts"),
          color: "#163d8e",
        },
        {
          id: "November-December-2021-Closing",
          bg: require("@/assets/img/news/1/bg.jpg"),
          name: this.$t("news-10-name"),
          info: [this.$t("news-10-subname")],
          description: this.$t("news-10-description"),
          ttl: this.$t("news-10-ttl"),
          sttl: this.$t("news-10-sttl"),
          txts: this.$t("news-10-txts"),
          color: "#163d8e",
        },
        {
          id: "Bansko-Summer-Season-2021",
          bg: require("@/assets/img/news/8/8.jpg"),
          name: this.$t("news-8-name"),
          info: [this.$t("news-8-subname")],
          description: this.$t("news-8-description"),
          ttl: this.$t("news-8-ttl"),
          sttl: this.$t("news-8-sttl"),
          txts: this.$t("news-8-txts"),
          color: "#FDFDFD",
        },
        {
          id: "Bansko-Summer-Time",
          bg: require("@/assets/img/news/7/7.jpg"),
          name: this.$t("news-7-name"),
          info: [this.$t("news-7-subname")],
          description: this.$t("news-7-description"),
          ttl: this.$t("news-7-ttl"),
          sttl: this.$t("news-7-sttl"),
          txts: this.$t("news-7-txts"),
          color: "#FDFDFD",
        },
        {
          id: "Health-and-relax-at-the-mountain",
          bg: require("@/assets/img/news/6.jpg"),
          name: this.$t("news-6-name"),
          info: [this.$t("news-6-subname")],
          description: this.$t("news-6-description"),
          ttl: this.$t("news-6-ttl"),
          sttl: this.$t("news-6-sttl"),
          txts: this.$t("news-6-txts"),
          color: "#FDFDFD",
        },
        {
          id: "2021-World-Ski-Championships",
          bg: require("@/assets/img/news/4.jpg"),
          name: this.$t("news-4-name"),
          info: [this.$t("news-4-subname")],
          description: this.$t("news-4-description"),
          ttl: this.$t("news-4-ttl"),
          sttl: this.$t("news-4-sttl"),
          txts: this.$t("news-4-txts"),
          color: "#FDFDFD",
        },
        {
          id: "Audi-Ski-World-Cup",
          bg: require("@/assets/img/news/5.jpg"),
          name: this.$t("news-5-name"),
          info: [this.$t("news-5-subname")],
          description: this.$t("news-5-description"),
          ttl: this.$t("news-5-ttl"),
          sttl: this.$t("news-5-sttl"),
          txts: this.$t("news-5-txts"),
          color: "#F8F9FE",
        },
        {
          id: "Autumn-Closing",
          bg: require("@/assets/img/news/1/bg.jpg"),
          name: this.$t("news-1-name"),
          subname: [this.$t("news-1-subname")],
          description: this.$t("news-1-description"),
          ttl: this.$t("news-1-ttl"),
          sttl: this.$t("news-1-sttl"),
          txts: this.$t("news-1-txts"),
          color: "#163d8e",
        },
      ],

      // SERVICES
      services: [
        {
          bg: require(`@/assets/img/banya/bg_${this.$i18n.locale}.jpg`),
          svg: require(`@/assets/img/banya/svg_${this.$i18n.locale}.svg`),
          name: this.$t("services-1-name"),
          info: [this.$t("home-pr-banya-p")],
          to: "/banya",
        },
        {
          bg: require("@/assets/img/aquapark/bg.jpg"),
          svg: require(`@/assets/img/aquapark/svg_${this.$i18n.locale}.svg`),
          name: this.$t("services-2-name"),
          info: [this.$t("home-pr-aquapark-p")],
          to: "/aquapark",
        },
        {
          bg: require("@/assets/img/spa/bg.jpg"),
          svg: require(`@/assets/img/spa/svg_${this.$i18n.locale}.svg`),
          name: this.$t("services-3-name"),
          info: [this.$t("home-pr-spa-p")],
          to: "/spa",
        },
        {
          bg: require("@/assets/img/kids/bg.jpg"),
          svg: require(`@/assets/img/kids/svg_${this.$i18n.locale}.svg`),
          name: this.$t("services-4-name"),
          info: [this.$t("home-pr-kids-p")],
          to: "/kids",
        },
      ],

      // SPECIAL OFFERS
      offers: [
        // Декемврийско бягство
        // {
        //   id: "december-escape",
        //   title: this.$t("offer-december-escape-title"),
        //   bg: require("@/assets/img/offers/december-escape/bg.jpg"),
        //   svg: require(`@/assets/img/offers/december-escape/svg_${this.$i18n.locale}.svg`),
        //   prices: require(`@/assets/img/offers/december-escape/table1-${this.$i18n.locale}.svg`),
        //   info: this.$t("offer-december-escape-info"),
        //   ttl: this.$t("offer-december-escape-ttl"),
        //   description: this.$t("offer-december-escape-description"),
        //   txts: this.$t("offer-december-escape-description"),
        //   options: [],
        //   // additional: {
        //   //   title: this.$t("offer-december-escape-additional-title"),
        //   //   texts: this.$t("offer-december-escape-txts"),
        //   // },
        //   inquiry: {
        //     mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
        //     bedrooms: ["1", "2"],
        //   },
        // },
        // СКИ ОФЕРТИ ЗАКУСКА + ВЕЧЕРЯ
        // {
        //   id: "ski-packages-breakfast&dinner-included",
        //   title: this.$t("offer-ski-packages-breakfast&dinner-included-title"),
        //   bg: require("@/assets/img/offers/ski-packages-breakfast&dinner-included/bg.jpg"),
        //   svg: require(`@/assets/img/offers/ski-packages-breakfast&dinner-included/svg_${this.$i18n.locale}.svg`),
        //   prices: require(`@/assets/img/offers/ski-packages-breakfast&dinner-included/table1-${this.$i18n.locale}.svg`),
        //   info: this.$t("offer-ski-packages-breakfast&dinner-included-info"),
        //   ttl: this.$t("offer-ski-packages-breakfast&dinner-included-ttl"),
        //   description: this.$t("offer-ski-packages-breakfast&dinner-included-description"),
        //   txts: this.$t("offer-ski-packages-breakfast&dinner-included-description"),
        //   options: [],
        //   additional: {
        //     title: this.$t("offer-ski-packages-breakfast&dinner-included-additional-title"),
        //     texts: this.$t("offer-ski-packages-breakfast&dinner-included-txts"),
        //   },
        //   inquiry: {
        //     mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
        //     bedrooms: ["1", "2"],
        //   },
        // },

        // СКИ ОФЕРТИ ЗАКУСКА

        // {
        //   id: "ski-packages-breakfast-included",
        //   title: this.$t("offer-ski-packages-breakfast-included-title"),
        //   bg: require("@/assets/img/offers/ski-packages-breakfast-included/bg.jpg"),
        //   svg: require(`@/assets/img/offers/ski-packages-breakfast-included/svg_${this.$i18n.locale}.svg`),
        //   prices: require(`@/assets/img/offers/ski-packages-breakfast-included/table1-${this.$i18n.locale}.svg`),
        //   info: this.$t("offer-ski-packages-breakfast-included-info"),
        //   ttl: this.$t("offer-ski-packages-breakfast-included-ttl"),
        //   description: this.$t("offer-ski-packages-breakfast-included-description"),
        //   txts: this.$t("offer-ski-packages-breakfast-included-description"),
        //   options: [],
        //   additional: {
        //     title: this.$t("offer-ski-packages-breakfast-included-additional-title"),
        //     texts: this.$t("offer-ski-packages-breakfast-included-txts"),
        //   },
        //   inquiry: {
        //     mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
        //     bedrooms: ["1", "2"],
        //   },
        // },

        // spa-relax

        // {
        //   id: "mountain-spa-relax",
        //   title: this.$t("offer-mountain-spa-relax-title"),
        //   bg: require("@/assets/img/offers/mountain-spa-relax/bg.jpg"),
        //   svg: require(`@/assets/img/offers/mountain-spa-relax/svg_${this.$i18n.locale}.svg`),
        //   prices: require(`@/assets/img/offers/mountain-spa-relax/table1-${this.$i18n.locale}.svg`),
        //   info: this.$t("offer-mountain-spa-relax-info"),
        //   ttl: this.$t("offer-mountain-spa-relax-ttl"),
        //   description: this.$t("offer-mountain-spa-relax-description"),
        //   txts: this.$t("offer-mountain-spa-relax-description"),
        //   options: [],
        //   additional: {
        //     title: this.$t("offer-mountain-spa-relax-additional-title"),
        //     texts: this.$t("offer-mountain-spa-relax-txts"),
        //   },
        //   inquiry: {
        //     mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
        //     bedrooms: ["1", "2"],
        //   },
        //   gallery: [
        //     require("@/assets/img/offers/mountain-spa-relax/1.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/2.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/3.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/4.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/5.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/6.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/7.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/8.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/9.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/10.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/11.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/12.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/13.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/14.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/15.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/16.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/17.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/18.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/19.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/20.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/21.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/22.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/23.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/24.jpg"),
        //     require("@/assets/img/offers/mountain-spa-relax/25.jpg"),
        //   ],
        // },

        //  Зимно бягство
        {
          id: "winter-escape-2025",
          title: this.$t("offer-winter-escape-2025-title"),
          bg: require("@/assets/img/offers/winter-escape-2025/bg.jpg"),
          svg: require(`@/assets/img/offers/winter-escape-2025/svg_${this.$i18n.locale}.svg`),
          prices: require(`@/assets/img/offers/winter-escape-2025/table1-${this.$i18n.locale}.svg`),
          info: this.$t("offer-winter-escape-2025-info"),
          ttl: this.$t("offer-winter-escape-2025-ttl"),
          description: this.$t("offer-winter-escape-2025-description"),
          txts: this.$t("offer-winter-escape-2025-description"),
          options: [],
          additional: {
            title: this.$t("offer-winter-escape-2025-additional-title"),
            texts: this.$t("offer-winter-escape-2025-txts"),
          },
          inquiry: {
            mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
            bedrooms: ["1", "2"],
          },
          gallery: [
          require("@/assets/img/offers/winter-escape-2025/1.jpg"),
            require("@/assets/img/offers/winter-escape-2025/12.jpg"),
            require("@/assets/img/offers/winter-escape-2025/10.jpg"),
            require("@/assets/img/offers/winter-escape-2025/17.jpg"),
            require("@/assets/img/offers/winter-escape-2025/2.jpg"),
            require("@/assets/img/offers/winter-escape-2025/3.jpg"),
            require("@/assets/img/offers/winter-escape-2025/15.jpg"),
            require("@/assets/img/offers/winter-escape-2025/4.jpg"),
            require("@/assets/img/offers/winter-escape-2025/14.jpg"),
            require("@/assets/img/offers/winter-escape-2025/5.jpg"),
            require("@/assets/img/offers/winter-escape-2025/16.jpg"),
            require("@/assets/img/offers/winter-escape-2025/6.jpg"),
            require("@/assets/img/offers/winter-escape-2025/8.jpg"),
            require("@/assets/img/offers/winter-escape-2025/11.jpg"),
            require("@/assets/img/offers/winter-escape-2025/13.jpg"),
          ],
        },

        // Mountain Relax 2024
        // {
        //   id: "mountain-relax-2024",
        //   title: this.$t("offer-mountain-relax-2024-title"),
        //   bg: require("@/assets/img/offers/mountain-relax-2024/bg.jpg"),
        //   svg: require(`@/assets/img/offers/mountain-relax-2024/svg_${this.$i18n.locale}.svg`),
        //   prices: require(`@/assets/img/offers/mountain-relax-2024/table1-${this.$i18n.locale}.svg`),
        //   info: this.$t("offer-mountain-relax-2024-info"),
        //   ttl: this.$t("offer-mountain-relax-2024-ttl"),
        //   description: this.$t("offer-mountain-relax-2024-description"),
        //   txts: this.$t("offer-mountain-relax-2024-description"),
        //   options: [],
        //   additional: {
        //     title: this.$t("offer-mountain-relax-2024-additional-title"),
        //     texts: this.$t("offer-mountain-relax-2024-txts"),
        //   },
        //   inquiry: {
        //     mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
        //     bedrooms: ["1", "2"],
        //   },
        // },

        // Mountain Relax 2025

        {
          id: "mountain-relax-2025",
          title: this.$t("offer-mountain-relax-2025-title"),
          bg: require("@/assets/img/offers/mountain-relax-2025/bg.jpg"),
          svg: require(`@/assets/img/offers/mountain-relax-2025/svg_${this.$i18n.locale}.svg`),
          prices: require(`@/assets/img/offers/mountain-relax-2025/table1-${this.$i18n.locale}.svg`),
          info: this.$t("offer-mountain-relax-2025-info"),
          ttl: this.$t("offer-mountain-relax-2025-ttl"),
          description: this.$t("offer-mountain-relax-2025-description"),
          txts: this.$t("offer-mountain-relax-2025-description"),
          options: [],
          additional: {
            title: this.$t("offer-mountain-relax-2025-additional-title"),
            texts: this.$t("offer-mountain-relax-2025-txts"),
          },
          inquiry: {
            mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
            bedrooms: ["1", "2"],
          },
        },

        // ВЕЛИКДЕН

        {
          id: "easter-2025",
          title: this.$t("offer-EASTER2025-title"),
          bg: require("@/assets/img/offers/easter-2025/bg.jpg"),
          svg: require(`@/assets/img/offers/easter-2025/svg_${this.$i18n.locale}.svg`),
          prices: require(`@/assets/img/offers/easter-2025/table1-${this.$i18n.locale}.svg`),
          info: this.$t("offer-EASTER2025-info"),
          ttl: this.$t("offer-EASTER2025-ttl"),
          description: this.$t("offer-EASTER2025-description"),
          txts: this.$t("offer-EASTER2025-description"),
          options: [],
          additional: {
            title: this.$t("offer-EASTER2025-additional-title"),
            texts: this.$t("offer-EASTER2025-txts"),
          },
          inquiry: {
            mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
            bedrooms: ["1", "2"],
          },
        },

        //  ПЛАНИНСКО ЛЯТО & АКВАПАРК 

        {
          id: "mountain-summer-aquapark-2025",
          title: this.$t("offer-mountain-summer-aquapark-2025-title"),
          bg: require("@/assets/img/offers/mountain-summer-aquapark-2025/bg.jpg"),
          svg: require(`@/assets/img/offers/mountain-summer-aquapark-2025/svg_${this.$i18n.locale}.svg`),
          prices: require(`@/assets/img/offers/mountain-summer-aquapark-2025/table1-${this.$i18n.locale}.svg`),
          info: this.$t("offer-mountain-summer-aquapark-2025-info"),
          ttl: this.$t("offer-mountain-summer-aquapark-2025-ttl"),
          description: this.$t("offer-mountain-summer-aquapark-2025-description"),
          txts: this.$t("offer-mountain-summer-aquapark-2025-description"),
          options: [],
          additional: {
            title: this.$t("offer-mountain-summer-aquapark-2025-additional-title"),
            texts: this.$t("offer-mountain-summer-aquapark-2025-txts"),
          },
          inquiry: {
            mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
            bedrooms: ["1", "2"],
          },
          gallery: [
            require("@/assets/img/offers/mountain-summer-aquapark-2025/1.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/12.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/10.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/17.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/2.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/3.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/15.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/4.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/14.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/5.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/16.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/6.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/8.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/11.jpg"),
            require("@/assets/img/offers/mountain-summer-aquapark-2025/13.jpg"),
          ],
        },

        // {
        //   id: "yoga-retreat-offer",
        //   title: this.$t("offer-yoga-retreat-title"),
        //   bg: require("@/assets/img/offers/yoga-retreat-offer/bg.jpg"),
        //   svg: require(`@/assets/img/offers/yoga-retreat-offer/svg_${this.$i18n.locale}.svg`),
        //   prices: require(`@/assets/img/offers/yoga-retreat-offer/table1-${this.$i18n.locale}.svg`),
        //   info: this.$t("offer-yoga-retreat-info"),
        //   ttl: this.$t("offer-yoga-retreat-ttl"),
        //   description: this.$t("offer-yoga-retreat-description"),
        //   txts: this.$t("offer-yoga-retreat-description"),
        //   options: [],
        //   additional: {
        //     title: this.$t("offer-yoga-retreat-additional-title"),
        //     texts: this.$t("offer-yoga-retreat-txts"),
        //   },
        //   gallery: [
        //     require("@/assets/img/offers/yoga-retreat-offer/1.jpg"),
        //   ],
        // },
        
        // {
        //   id: "easter-2022",
        //   title: this.$t("offer-easter-2022-title"),
        //   bg: require("@/assets/img/offers/easter-2022/bg.jpg"),
        //   svg: require(`@/assets/img/offers/easter-2022/svg_${this.$i18n.locale}.svg`),
        //   prices: require(`@/assets/img/offers/easter-2022/table1-${this.$i18n.locale}.svg`),
        //   info: this.$t("offer-easter-2022-info"),
        //   ttl: this.$t("offer-easter-2022-ttl"),
        //   description: this.$t("offer-easter-2022-description"),
        //   txts: this.$t("offer-easter-2022-description"),
        //   options: [],
        //   additional: {
        //     title: this.$t("offer-easter-2022-additional-title"),
        //     texts: this.$t("offer-easter-2022-txts"),
        //   },
        //   inquiry: {
        //     mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
        //     bedrooms: ["1", "2"],
        //   },
        // },
        // {
        //   id: "winter-family-vacation",
        //   title: this.$t("offer-202202-title"),
        //   bg: require("@/assets/img/offers/202202/bg.jpg"),
        //   svg: require(`@/assets/img/offers/202202/svg_${this.$i18n.locale}.svg`),
        //   prices: require(`@/assets/img/offers/202202/table1-${this.$i18n.locale}.svg`),
        //   info: this.$t("offer-202202-info"),
        //   ttl: this.$t("offer-202202-ttl"),
        //   description: this.$t("offer-202202-description"),
        //   txts: this.$t("offer-202202-description"),
        //   options: [],
        //   additional: {
        //     title: this.$t("offer-202202-additional-title"),
        //     texts: this.$t("offer-202202-txts"),
        //   },
        //   inquiry: {
        //     mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
        //     bedrooms: ["1", "2"],
        //   },
        // },
      ],

      // SPA
      "spa-services": [
        {
          id: "services",
          title: this.$t("spa-s-alt"),
          bg: require("@/assets/img/spa/spa1.jpg"),
          svg: require(`@/assets/img/spa-s/svg_${this.$i18n.locale}.svg`),
          options: [
            {
              img: require("@/assets/img/showcase/mountain.svg"),
              txt: '',
            },
            {
              img: require("@/assets/img/showcase/superior.svg"),
              txt: '',
            },
          ],
          gallery: [
            require("@/assets/img/spa-w/s1-1.jpg"),
            require("@/assets/img/spa-w/s1-2.jpg"),
            require("@/assets/img/spa-w/s2-1.jpg"),
            require("@/assets/img/spa-w/s2-2.jpg"),
            require("@/assets/img/spa-w/s2-3.jpg"),
            require("@/assets/img/spa-w/s3-1.jpg"),
            require("@/assets/img/spa-w/s4-2.jpg"),
            require("@/assets/img/spa-w/s5-1.jpg"),
            require("@/assets/img/spa-w/s5-2.jpg"),
            require("@/assets/img/spa-w/s5-3.jpg"),
          ],
          inquiry: {
            mountain: [this.$t("inquiry.yes"), this.$t("inquiry.no")],
            bedrooms: ["1", "2"],
          },
        },
      ],
      spa: [
        {
          bg: require(`@/assets/img/spa/spa1.jpg`),
          svg: require(`@/assets/img/spa-s/svg_${this.$i18n.locale}.svg`),
          name: this.$t("spa-s-name"),
          info: this.$t("spa-s-info"),
          to: "/spa/services",
        },
        // {
        //   bg: require(`@/assets/img/spa/spa2.jpg`),
        //   svg: require(`@/assets/img/spa-o/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("spa-o-name"),
        //   info: this.$t("spa-o-info"),
        //   to: "/spa/offers",
        // },
        {
          bg: require(`@/assets/img/spa/spa3.jpg`),
          svg: require(`@/assets/img/spa-w/svg_${this.$i18n.locale}.svg`),
          name: this.$t("spa-w-name"),
          info: this.$t("spa-w-info"),
          to: "/spa/wellness-and-fitness",
        },
      ],
      scrollLeft: 0,
      opened: null,
    };
  },
  watch: {
    $route: {
      handler: "handleRoutes",
      immediate: true,
    },
  },
  methods: {
    // handleWheel(e) {
    //   const item = document.getElementById("showcases");
    //   if (e.deltaY > 0) {
    //     item.scrollLeft += window.innerWidth / 4;
    //   } else {
    //     item.scrollLeft -= window.innerWidth / 4;
    //   }
    // },
    openShowcase(x) {
      if (this.$route.params.id !== undefined) {
        return;
      }
      if (x.id) {
        this.$router.push(`/${this.$route.name}/${x.id}`);
      } else if (x.to) {
        this.$router.push(x.to);
      }
    },
    closeShowcase() {
      if (this.$route.name === 'spa-services') this.$router.push(`/spa`);
      else this.$router.push(`/${this.$route.name}`);
    },
    handleRoutes(to) {
      const item = document.getElementById("showcases");
      if (to.name === "spa-services") {
        this.opened = 0;
      } else if (to.params.id) {
        const i = to.params.id;
        const index = this.type.map((r) => r.id).indexOf(i);

        if (this.type[index]) {
          this.opened = index;
          if (item) {
            this.scrollLeft = item.scrollLeft;
          }
        } else {
          this.$router.push("/404");
        }
      } else {
        this.opened = null;

        if (item) {
          setTimeout(() => {
            item.scrollLeft = this.scrollLeft;
            this.scrollLeft = 0;

            item.scroll({ x: this.scrollLeft, behavior: "smooth" });
          }, 500);
        }
      }
    },
  },
  // mounted() {
  //   window.addEventListener("wheel", this.handleWheel);
  // },
  // destroyed() {
  //   window.removeEventListener("wheel", this.handleWheel);
  // },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";

.showcases-grand-wrapper {
  overflow: hidden;
}

header {
  // background-image: url("../../assets/img/showcases.jpg");
  display: flex;
  // height: 100vh;
  // max-height: 100vh;
  min-height: 100vh;
  // height: calc(var(--vh, 1vh) * 100);
  // max-height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  @include transition($transition);

  &::before,
  &::after {
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &::before {
    background-image: url("../../assets/img/showcases.jpg");
    background-size: cover;
  }

  &::after {
    background-color: rgba($color: #000, $alpha: 0.7);
  }
}

// .showcases-wrapper--scrollable {
//   header {
//     max-height: initial;
//   }
// }
aside {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  height: 100vh;
  padding: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 16%;
  z-index: 1;

  h2,
  .svg {
    height: auto;
    width: 150%;
    @include transform(rotate(-90deg));
    @include transition($transition);

    &.hidden {
      opacity: 0;
      right: 0;
    }
  }
}

.showcases {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  gap: 50px 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding-left: 16%;
  padding-top: 100px;
  padding-bottom: 10px;

  .showcase {
    align-items: flex-end;
    cursor: pointer;
    display: flex;
    flex-basis: calc(25% - 10px);
    height: calc(90vh - 100px);
    padding: 20px 10px;
    position: relative;

    .bg {
      background-color: #c7c7c7;
      height: calc(100% - 20px);
      right: 0;
      top: 10px;
      position: absolute;
      width: 100%;
      @include transition(all 1s $cubic);
    }

    &:nth-child(4n + 2) {
      .bg {
        background-color: #3b889f;
      }
    }

    &:nth-child(4n + 3) {
      .bg {
        background-color: #e2a151;
      }
    }

    &:nth-child(4n + 4) {
      .bg {
        background-color: #b83419;
      }
    }

    .img {
      background-position: center;
      background-size: cover;
      height: 100%;
      left: 10px;
      position: absolute;
      top: 0;
      width: calc(100% - 20px);
      z-index: 1;
      @include transition(all 1s $cubic);
    }

    &:hover {
      .img:not(.appear-img-enter-active):not(.appear-img-leave-active) {
        background-position: 0 50%;
        height: calc(100% - 20px);
        top: 10px;
        left: 0;
        width: 100%;
      }

      .bg:not(.appear-bg-enter-active):not(.appear-bg-leave-active) {
        height: 100%;
        right: 10px;
        top: 0;
        width: calc(100% - 20px);

        &:nth-child(4n + 2) {
          .bg {
            background-color: #3b889f;
          }
        }

        &:nth-child(4n + 3) {
          .bg {
            background-color: #e2a151;
          }
        }

        &:nth-child(4n + 4) {
          .bg {
            background-color: #b83419;
          }
        }
      }
    }

    .info {
      align-items: center;
      background-color: rgba($color: #fff, $alpha: 0.9);
      display: flex;
      flex-direction: column;
      height: 160px;
      position: relative;
      width: 90%;
      z-index: 2;

      h2 {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        display: flex;
        text-align: center;
        height: 50%;

        p {
          font-size: 1.4rem;
          line-height: 1.2rem;
          // font-size: 3rem;
          // line-height: 2rem;
        }
      }

      img {
        height: 50%;
        filter: invert(1);
        max-width: 90%;
        @include transform(translateY(-10%));
      }

      .texts {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 50%;
        justify-content: center;
        padding: 0 5px;
        text-align: center;
        width: 100%;
      }

      p {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }

  &.big {
    .showcase {
      height: calc(100vh - 150px);
    }
  }
}

.slide-top-enter-active,
.slide-top-leave-active {
  @include transition($transition);
}

.slide-top-enter {
  opacity: 0;
  @include transform(translateY(-100%));
}

.slide-top-leave-to {
  opacity: 0;
  @include transform(translateY(100%));
}

.slide-enter-active,
.slide-leave-active {
  @include transition($transition);
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  @include transform(translateX(1rem));
}

.appear-img-enter-active,
.appear-img-leave-active {
  @include transition(all 2s $cubic !important);
  max-width: 100%;
}

.appear-img-enter,
.appear-img-leave-to {
  opacity: 0;
  max-width: 0;
}

.appear-bg-enter-active,
.appear-bg-leave-active {
  @include transition(all 2s $cubic !important);
  max-width: 100%;
  top: 10px;
  height: calc(100% - 20px);
}

.appear-bg-enter,
.appear-bg-leave-to {
  opacity: 0;
  max-width: 0;
  top: -10% !important;
  height: 120% !important;
}

@media screen and (max-width: $s) {
  .showcases-grand-wrapper {
    overflow: hidden;
  }

  header {
    flex-direction: column;
  }

  aside {
    position: static;
    height: initial;
    margin-bottom: 1rem;
    padding-top: 100px;
    width: 100%;

    .svg {
      width: 50%;
      @include transform(initial);
    }
  }

  .showcases {
    padding: 1.5rem;
    gap: 1.5rem;
    flex-direction: column;

    &.big .showcase,
    .showcase {
      flex-basis: 50vh;
      height: 50vh;

      .info {
        width: 100%;
      }
    }
  }
}
</style>
